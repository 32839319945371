exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-happiness-manifest-happiness-js": () => import("./../../../src/pages/blog/happiness/manifest-happiness.js" /* webpackChunkName: "component---src-pages-blog-happiness-manifest-happiness-js" */),
  "component---src-pages-blog-health-lack-of-sleep-js": () => import("./../../../src/pages/blog/health/lack-of-sleep.js" /* webpackChunkName: "component---src-pages-blog-health-lack-of-sleep-js" */),
  "component---src-pages-blog-latest-cleaning-patch-js": () => import("./../../../src/pages/blog/latest/cleaning-patch.js" /* webpackChunkName: "component---src-pages-blog-latest-cleaning-patch-js" */),
  "component---src-pages-blog-life-food-pyramide-js": () => import("./../../../src/pages/blog/life/food-pyramide.js" /* webpackChunkName: "component---src-pages-blog-life-food-pyramide-js" */),
  "component---src-pages-blog-wellbeing-mindfulness-healing-js": () => import("./../../../src/pages/blog/wellbeing/mindfulness-healing.js" /* webpackChunkName: "component---src-pages-blog-wellbeing-mindfulness-healing-js" */),
  "component---src-pages-disclosure-js": () => import("./../../../src/pages/disclosure.js" /* webpackChunkName: "component---src-pages-disclosure-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

